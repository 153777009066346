import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import AppInput from "../inputs/AppInput";
import bg_img from "../../assets/img/bg-img.png";

const Hero = () => {
  return (
    <div style={styles.hero2}>
      <Box sx={styles.hero}>
        <Typography sx={styles.heroHeading} fontWeight="bold">
          A Learning platform for MAVERICKS
        </Typography>
        <Typography mt={4} sx={styles.heroSubHeading}>
          At Quales Consulting we give you the best all the time.
        </Typography>
      </Box>
    </div>
  );
};

const styles = {
  hero2: {
    backgroundImage: `url(${bg_img})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "95vh",
    position: "relative",
  },
  hero: {
    // height: "100%",
    pt: "10%",
    textAlign: "center",
    color: "#fff",
  },
  heroHeading: {
    width: "80%",
    mx: "auto",
    fontSize: {
      xs: "2rem",
      sm: "2.5rem",
      md: "3rem",
    },
  },
  heroSubHeading: {
    width: "80%",
    mx: "auto",
  },
  heroForm: {
    bgcolor: "#FAFAFA",
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    py: "5px",
    px: "7px",
    position: "absolute",
    bottom: "-40px",
    left: "0",
    right: "0",
    mx: "auto",
    zIndex: "tooltip",
  },
};

export default Hero;
