import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Home } from "./pages/website/Home";
import Login from "./pages/onboarding/Login";
import { PAGES } from "./utils/constant";
import Signup from "./pages/onboarding/Signup";
import Dashboard from "./pages/dashboard/Dashboard";
import PrivateRoute from "./routes/PrivateRoute";
import CoursesIndex from "./pages/courses/CoursesIndex";
import { StoreProvider } from "./context/store";
import AddCourse from "./pages/courses/AddCourse";
import ViewCourse from "./pages/courses/ViewCourse";
import EditCourse from "./pages/courses/EditCourse";

function App() {
  return (
    <StoreProvider>
      <ToastContainer />
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path={PAGES.login} element={<Login />} />
            <Route path={PAGES.signup} element={<Signup />} />
            <Route
              path={PAGES.dashboard}
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path={PAGES.courses}
              element={
                <PrivateRoute>
                  <CoursesIndex />
                </PrivateRoute>
              }
            />
            <Route
              path={PAGES.addCourse}
              element={
                <PrivateRoute>
                  <AddCourse />
                </PrivateRoute>
              }
            />
            <Route
              path={`${PAGES.courses}/:id`}
              element={
                <PrivateRoute>
                  <ViewCourse />
                </PrivateRoute>
              }
            />
              <Route
              path={`${PAGES.editCourse}/:id`}
              element={
                <PrivateRoute>
                  <EditCourse />
                </PrivateRoute>
              }
            />
          </Routes>
          
        </Router>
    </StoreProvider>
  );
}

export default App;
