import React, { createContext, useReducer } from "react";
import { setLocalStorageValue } from "../utils/storageHelpers";
import * as actions from "./actions";

const defaultHiddenColumns = [0, 1, 2]
const initialState = {
  user: null,
  courses: [] 
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_USER:
      setLocalStorageValue("user", {...state.user, ...action.payload})
      return {
        ...state,
        user: action.payload,
      };
      case actions.SET_COURSES:
        console.log("got here")
        return {
          ...state,
          courses: action.payload,
        };
    default:
      return state;
  }
};

const StoreContext = createContext({ state: initialState, dispatch: () => { } });

function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
}

export { StoreContext, StoreProvider };