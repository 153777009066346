import { Grid, Typography, } from '@mui/material'
import React, { useContext } from 'react'
import LoginImage from '../../assets/img/login_bg.png'
import LoginForm from '../../components/onboarding/LoginForm'

const Login = () => {
    return (
        <Grid container >
            <Grid p md={6} sx={styles.leftGrid}>
            </Grid>
            <Grid p md={6} xs={12} sx={styles.rightGrid} >
                <LoginForm />
            </Grid>
        </Grid >
    )
}

const styles = {
    leftGrid: {
        backgroundImage: `url(${LoginImage})`,
        backgroundSize: 'cover',
        height: "100vh",
        display: { xs: 'none', md: 'block' }
    },
    rightGrid: {
        paddingTop: {xs: 5, md: 12},
        px: {xs: 5, md: 12}
    },
}

export default Login