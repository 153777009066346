export const setLocalStorageValue = (key,value) =>{
    let stringValue = JSON.stringify(value);
    localStorage.setItem(key, stringValue)
}



export const getLocalStorageValue = (key) =>{
    let value = localStorage.getItem(key);
    return value;
}

export const deleteLocalStorageValue = (key) =>{
    localStorage.removeItem(key)
}

export const categories = [
    {id:1, name: "Quality Assurance"},
    {id:2, name: "Product Design"},
    {id:3, name: "Software Development"},
]