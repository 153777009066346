import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { red, purple, green } from "@mui/material/colors";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PersonIcon from "@mui/icons-material/Person";
import PaidIcon from "@mui/icons-material/Paid";

const WhyLists = [
  {
    id: 1,
    header: "Get your money worth",
    body: "Janniel Housing Estate is an integrated, mixed-use city planned for over 2,000 hectares in the Lekki Free Zone. Over 50 companies are m...",
    icon: <AccountBalanceWalletIcon />,
    bgcolor: red[500],
  },
  {
    id: 2,
    header: "Zero omo-onile issues",
    body: "Janniel Housing Estate is an integrated, mixed-use city planned for over 2,000 hectares in the Lekki Free Zone. Over 50 companies are m...",
    icon: <PersonIcon />,
    bgcolor: purple[500],
  },
  {
    id: 3,
    header: "10,000+ properties sold",
    body: "Janniel Housing Estate is an integrated, mixed-use city planned for over 2,000 hectares in the Lekki Free Zone. Over 50 companies are m...",
    icon: <PaidIcon />,
    bgcolor: green[500],
  },
];

const WhyChooseUs = () => {
  return (
    <Box sx={styles.box}>
      <Typography variant="h5" sx={{ mb: "30px" }}>
        Why Choose Us?
      </Typography>
      <Box component="div" sx={styles.cardDiv}>
        {WhyLists.map((whyList) => {
          return (
            <Card sx={styles.card} key={whyList.id}>
              <CardHeader
                avatar={
                  <Avatar
                    variant="rounded"
                    sx={{ bgcolor: `${whyList.bgcolor}`, boxShadow: 5 }}
                  >
                    {whyList.icon}
                  </Avatar>
                }
              />
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  {whyList.header}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {whyList.body}
                </Typography>
                <Link href="#">see more details</Link>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

const styles = {
  box: {
    pt: 15,
    pb: 3,
    width: "90%",
    mx: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardDiv: {
    width: "100%",
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    gap: 3,
  },
  card: {
    width: {
      xs: "90%",
      md: "30%",
    },
    mx: {
      xs: "auto",
    },
  },
};

export default WhyChooseUs;
