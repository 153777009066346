import { Typography } from "@mui/material";
import React from "react";

const HeaderText = ({ text, paddingLeft }) => {
  return (
    <Typography
      component="h1"
      variant="h5"
      color="inherit"
      sx={styles.headerText(paddingLeft)}
    >
      {text}
    </Typography>
  );
};

const styles = {
  headerText: (paddingLeft) => {
    return { flexGrow: 1, fontWeight: 500, pl: paddingLeft, mb: 1 };
  },
};

export default HeaderText;
