import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function BreadCrumb() {
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="primary"
      href="/"
      onClick={handleClick}
    >
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="primary"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      Core
    </Link>,
    <Typography key="3" color="primary" sx={styles.routeText}>
      Breadcrumb
    </Typography>,
  ];

  return (
    <Stack spacing={2} sx={styles.stack}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}

const styles = {
  routeText: { fontWeight: 500 },
  stack: { flexGrow: 1 },
};
