import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

const AppButton = ({ variant, btnText, onClick, color, fullWidth, size, isLoading, loadingComponent, customStyles }) => {
    return (
      <Button
        sx={{ ...styles, ...customStyles }}
        color={color}
        variant={variant}
        onClick={onClick}
        disabled={isLoading}
        fullWidth={fullWidth}
        size={size}
      >
        {isLoading ? "Loading..." : btnText}
      </Button>
    );
}

const styles = {

}
AppButton.propTypes = {
    variant: PropTypes.string,
    btnText: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    fullWidth: PropTypes.bool,
    customStyles: PropTypes.object
}

AppButton.defaultProps = {
    variant: "contained",
    onClick: () => { },
    btnText: "text",
    color: "primary",
    size: "large",
    fullWidth: true,
    loadingComponent: () => <p>Loading...</p>,
    customStyles: {}
};

export default AppButton