import { Box, Button, Container, Divider, Typography } from "@mui/material";
import React from "react";
import AppInput from "../inputs/AppInput";
import AppButton from "../buttons/AppButton";
import { APP_COLOURS, PAGES } from "../../utils/constant";
import GoogleIcon from "../../assets/svg/GoogleIcon";
import { useNavigate } from "react-router-dom";

const SignupForm = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Typography sx={styles.headerText}>Create Account</Typography>
      <AppInput label="Full Name" />
      <AppInput label="Email Address" />
      <AppInput label="Phone Number" />
      <AppInput label="Referal Code (optional)" />
      <AppInput label="Password" />

      <AppButton btnText="Create Account" customStyles={{ marginTop: 6 }} />
      <Divider sx={styles.divider}>OR</Divider>
      <Box sx={styles.continueBox}>
        <Button
          sx={styles.continueBtn}
          variant="text"
          startIcon={<GoogleIcon />}
        >
          Continue with Google
        </Button>
      </Box>
      <Box sx={styles.alreadyHaveAcc}>
        <Typography sx={styles.alreadyHaveText}>Already a user?</Typography>
        <Button
          color="primary"
          variant="text"
          onClick={() => navigate(PAGES.login)}
          sx={styles.loginBtn}
        >
          Login
        </Button>
      </Box>
    </Container>
  );
};

const styles = {
  headerText: {
    fontSize: 38,
    fontWeight: 700,
    textAlign: "center",
    marginBottom: 4,
    color: APP_COLOURS.dark,
  },
  fgPassword: {
    textTransform: "none",
    fontSize: 16,
    float: "right",
    padding: 0,
    marginTop: "12px",
  },
  divider: { my: { xs: 3, md: 4 } },
  continueBox: { display: "flex", justifyContent: "center" },
  continueBtn: { textTransform: "none", fontSize: 16, color: APP_COLOURS.dark },
  alreadyHaveAcc: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    my: 3,
    alreadyHaveText: {
      fontSize: 16,
      color: APP_COLOURS.dark,
      marginTop: "2px",
    },
    loginBtn: {
      fontSize: 16,
      marginLeft: "5px",
      textTransform: "none",
      padding: 0,
      textDecoration: "underline",
    },
  },
};

export default SignupForm;
