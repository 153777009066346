import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import AppInput from "../inputs/AppInput";

const Footer = () => {
  return (
    <Box sx={styles.footerGrid}>
      <Grid container spacing={4} sx={{ mb: 5 }}>
        <Grid item sm={12} md={6} lg={8} sx={styles.subscribeGrid}>
          <Typography variant="h5" gutterBottom>
            Subscribe to our newsletter
          </Typography>
          <AppInput placeholder="Email Address" bgColor="#fff" />
          <Button variant="contained">Subscribe</Button>
        </Grid>
        <Grid item sm={12} md={6} lg={4}>
          <Typography variant="h6" gutterBottom>
            Connect with us
          </Typography>
          <Box sx={styles.footerConnect}>
            <Link href="#" underline="none" color="inherit">
              Facebook
            </Link>
            <Link href="#" underline="none" color="inherit">
              Twitter
            </Link>
            <Link href="#" underline="none" color="inherit">
              Instagram
            </Link>
            <Link href="#" underline="none" color="inherit">
              Disha
            </Link>
            <Link href="#" underline="none" color="inherit">
              LinkedIn
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Grid container columnSpacing={8} rowSpacing={4}>
        <Grid item sm={12} md={6} lg={4} sx={styles.logoAndLinksGrid}>
          <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
            QLearn
          </Typography>
          <Typography>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt
            voluptatem culpa aliquid, libero ipsum sint labore! Harum mollitia
            tenetur a, perspiciatis quia commodi explicabo labore consequuntur
            incidunt velit odit. Libero fugit neque nemo quas quidem nostrum et
            dolorum necessitatibus minus laboriosam aliquid corporis odit minima
            animi est, iste accusamus.
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} lg={4} sx={styles.logoAndLinksGrid}>
          <Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
            Quick Links
          </Typography>
          <Box sx={styles.footerLinks}>
            <Link href="#" underline="none" color="inherit">
              Home
            </Link>
            <Link href="#" underline="none" color="inherit">
              About
            </Link>
            <Link href="#" underline="none" color="inherit">
              Properties
            </Link>
            <Link href="#" underline="none" color="inherit">
              Contact Us
            </Link>
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={4}>
          <Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
            Contact
          </Typography>
          <Box sx={styles.footerLinks}>
            <Typography gutterBottom>info@gmail.com</Typography>
            <Typography gutterBottom>+234(0)0000000031</Typography>
            <Typography gutterBottom>
              FAS UK-Suite 9, 53-67 Whitehall Road, Aberdeen, AB25 2PQ, United
              Kingdom.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = {
  footerGrid: {
    bgcolor: "#1F1F1F",
    color: "#FFFFFF",
    padding: 7,
  },
  footerConnect: {
    width: "380px",
    display: "flex",
    justifyContent: "space-between",
    color: "inherit",
  },
  footerLinks: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    color: "inherit",
    height: "150px",
  },
  subscribeGrid: { pr: "50px", mb: { xs: "30px", sm: "30px" } },
  logoAndLinksGrid: { mb: { xs: "30px", sm: "30px" } },
};

export default Footer;
