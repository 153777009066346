import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import CourseDefault from "../../assets/img/property1.jpeg";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { red } from "@mui/material/colors";
// import PropTypes from "prop-types";
import { ArrowForward, Category, Cloud, CloudCircle, CloudOutlined, HouseOutlined, HouseRounded, LocationOn } from "@mui/icons-material";
import { categories } from "../../utils/storageHelpers";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../utils/constant";

const PropertyCard = ({ course }) => {
  const navigate = useNavigate()
  const getCategoryName = (id) =>{
    let category = categories.find(x => x.id == id);
    return category? category.name : "";
  }

  return (
    <Card sx={styles.card} onClick={() => navigate(`${PAGES.courses}/${course.id}`)}>
          <CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image={course.imageUrl && course.imageUrl.length > 0 ? course.imageUrl : CourseDefault}
          />
          <CardActions>
            <Typography sx={course.isPremium ?styles.cardHeading: styles.cardHeading2}>{course.isPremium ? "Premium" : "Regular"}</Typography>
            {course.location == "online" ? <CloudOutlined /> : <HouseOutlined />}
            <Button size="small" startIcon={<Category />} sx={styles.cardBtn}>
            {getCategoryName(course.categoryId)}
            </Button>
          </CardActions>
          <CardContent sx={styles.cardContent}>
            <Typography gutterBottom sx={styles.cardLocation} component="div">
              {course.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {course.description}
            </Typography>
          </CardContent>
          {/* <Typography gutterBottom sx={{fontSize:12}} component="div">
            Contact Agent
            </Typography> */}
          {/* {agent && (
            <CardContent sx={{ mt: 1 }}>
              <Typography gutterBottom sx={{ fontSize: 12 }} component="div">
                Contact Agent
              </Typography>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="name">
                    CV
                  </Avatar>
                }
                action={
                  <>
                    <IconButton aria-label="settings">
                      <PhoneIcon />
                    </IconButton>
                    <IconButton aria-label="settings">
                      <MailOutlineIcon />
                    </IconButton>
                  </>
                }
                title="Chudi Victor"
                subheader="4.8 ⭐"
              />
            </CardContent>
          )} */}
    </Card>
  );
};

const styles = {
  card: {
    width: { md: `calc(33.33% - 20px)`, sm: `calc(50% - 20px)` },
    m: "10px",
    cursor: "pointer"
  },
  viewBtn: {
    width: "100%",
    height: "100%",
    fontSize: 24,
    textTransform: "none",
  },
  cardHeading: {
    fontSize: 12,
    color: "#3B82F6",
    padding: "2px 4px",
    backgroundColor: "#EFF6FF",
    borderRadius: "4px",
    marginRight: 3,
  },
  cardHeading2: {
    fontSize: 12,
    color: "#EF5DA8",
    padding: "2px 4px",
    backgroundColor: "#FFEAF4",
    borderRadius: "4px",
    marginRight: 3,
  },
  cardDay: { fontSize: 12, padding: "2px 4px" },
  cardBtn: { color: "#202020", textTransform: "none", marginLeft: "auto" },
  cardContent: { pt: 1 },
  cardLocation: { fontSize: 18 },
};

PropertyCard.propTypes = {};

export default PropertyCard;
