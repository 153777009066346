import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SidebarListItem from "../../components/list/SidebarListItem";
import BreadCrumb from "../../components/header/BreadCrumb";
import AppButton from "../../components/buttons/AppButton";
import { useNavigate } from "react-router-dom";
import {
  deleteLocalStorageValue,
  setLocalStorageValue,
} from "../../utils/storageHelpers";
import { PAGES } from "../../utils/constant";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import { Person } from "@mui/icons-material";
import { StoreContext } from "../../context/store";

// function Copyright(props) {
//     return (
//         <Typography variant="body2" color="text.secondary" align="center" {...props}>
//             {'Copyright © '}
//             <Link color="inherit" href="https://mui.com/">
//                 Your Website
//             </Link>{' '}
//             {new Date().getFullYear()}
//             {'.'}
//         </Typography>
//     );
// }

const drawerWidth = 279;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const { state, dispatch } = useContext(StoreContext);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logout = () => {
    deleteLocalStorageValue("user");
    toast.success("Logged out successfully");
    setTimeout(() => {
      navigate(PAGES.login);
    }, 2000);
  };

  useEffect(()=>{
    if(width <= 800){
      setOpen(false)
    }
  },[])

  return (
    <>
      <Box sx={styles.mainBox}>
        <CssBaseline />
        <AppBar position="absolute" open={open} color="white">
          <Toolbar sx={styles.mainToolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={styles.boxBtn(open)}
            >
              <MenuIcon />
            </IconButton>
            <BreadCrumb />
            <IconButton color="inherit">
                <Person color="grey" />
                <Typography>{state.user?.name}</Typography>
            </IconButton>
            <AppButton
              variant="outlined"
              size="small"
              customStyles={{ marginLeft: 2 }}
              btnText="Log Out"
              fullWidth={false}
              onClick={logout}
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar sx={styles.otherToolbar}>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <SidebarListItem isOpen={open} />
          </List>
        </Drawer>
        <Box component="main" sx={styles.otherBox}>
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container>{children}</Grid>
            {/* <Copyright sx={{ pt: 4 }} /> */}
          </Container>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  mainBox: { display: "flex" },
  mainToolbar: {
    pr: "24px", // keep right padding when drawer closed
  },
  otherToolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    px: [1],
  },
  otherBox: {
    backgroundColor: (theme) =>
      theme.palette.mode === "light"
        ? theme.palette.white[100]
        : theme.palette.grey[900],
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  boxBtn: (open) => {
    return {
      marginRight: "36px",
      ...(open && { display: "none" }),
    };
  },
};

export default Layout;
