import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  CreditCard,
  Dashboard,
  Book,
  Event,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../utils/constant";

//TODO: sidebar active link and prover hover effect colos
const SidebarListItem = ({ isOpen }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <ListItemButton
        sx={styles.item(isOpen)}
        onClick={() => navigate(PAGES.courses)}
      >
        <ListItemIcon>
          <Book color="sidebarText" sx={styles.icon} />
        </ListItemIcon>
        <ListItemText primary="Courses" sx={styles.text} />
      </ListItemButton>
    </React.Fragment>
  );
};

const styles = {
  text: {
    color: "#9E9E9E",
    fontSize: 16,
    fontWeight: "400",
    "&:hover": {
      fontWeight: "600",
      color: "#5B8181",
    },
  },
  icon: {
    color: "#9E9E9E",
    "&:hover": {
      color: "#5B8181",
    },
  },
  item: (isOpen) => {
    return {
      paddingLeft: isOpen ? "40px" : "20px",
      height: 48,
      my: 1,
      "&:hover": {
        background: "#F0F4F4",
        fontWeight: "600",
        borderLeft: "solid 8px #5B8181",
      },
    };
  },
};

export default SidebarListItem;
