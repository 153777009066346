import { Grid, Typography, } from '@mui/material'
import React from 'react'
import SignupImage from '../../assets/img/signup_bg.png'
import SignupForm from '../../components/onboarding/SignupForm'

const Signup = () => {
    return (
        <Grid container >
            <Grid p md={6} sx={styles.leftGrid}>
            </Grid>
            <Grid p md={6} xs={12} sx={styles.rightGrid} >
                <SignupForm />
            </Grid>
        </Grid >
    )
}

const styles = {
    leftGrid: {
        backgroundImage: `url(${SignupImage})`,
        backgroundSize: 'cover',
        height: "100vh",
        display: { xs: 'none', md: 'block' }
    },
    rightGrid: {
        paddingTop: {xs: 5, md: 12},
        px: {xs: 5, md: 12}
    },
}

export default Signup