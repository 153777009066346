import React, { Fragment, useContext, useEffect, useState } from "react";
import PropertyCard from "../../components/property/PropertyCard";
import Property1 from "../../assets/img/property1.jpeg";
import Property2 from "../../assets/img/property2.png";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import HeaderText from "../../components/header/HeaderText";
import axios from "axios";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../utils/constant";
import { toast } from "react-toastify";
import { StoreContext } from "../../context/store";
import { SET_COURSES } from "../../context/actions";

const PropertyIndex = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  const { state, dispatch } = useContext(StoreContext);

  useEffect(() => {
    console.log("state.courses", state.courses);
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/courses`)
      .then((res) => {
        console.log("res all", res.data.data);
        ///////
        const courses = res.data?.data
        console.log('courses', courses)
        dispatch({ type: SET_COURSES, payload: courses })
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
        toast.error(error?.data?.errorMessage || error.message);
      });
  }, []);
  return (
    <Fragment>
      {/* Property Section start */}
      <Grid container>
        <HeaderText text="List of Courses" paddingLeft={1} />
        <Button
          variant="contained"
          sx={styles.btn}
          startIcon={<Add />}
          onClick={() => navigate(PAGES.addCourse)}
        >
          Add Course
        </Button>
      </Grid>
      {isLoading && (
        <Typography sx={styles.loading} variant="h3">
          🏋️‍♂️ Loading Courses...
        </Typography>
      )}
      {!isLoading && state.courses.length == 0 && 
        <Typography variant="h3" sx={styles.loading}>
          No Course Available 📚
        </Typography>}
        {!isLoading && state.courses.length > 0 && 
        <>
          {state.courses.map((course => <PropertyCard course={course} />
          ))}
        </>}
    </Fragment>
  );
};

const styles = {
  btn: {
    textTransform: "none",
    marginRight: "10px",
  },
  loading: { margin: "auto", marginTop: "20%" },
  box: { height: 550, width: { xs: "100%", md: "95%" } },
  eventsCard: { height: 550, width: "100%" },
  grid: { mt: 3 },
};

export default PropertyIndex;
