import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { PAGES } from "../../utils/constant";

const pages = ["Training", "Courses", "Contact Us"];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  // eslint-disable-next-line
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  // eslint-disable-next-line
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // eslint-disable-next-line
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" color="white">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AdbIcon sx={styles.adbIconMd} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={styles.logoTextMd}
          >
            QLearn
          </Typography>

          <Box sx={styles.navMobile}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={styles.menuXs}
            >
              <MenuItem onClick={()=>{
                navigate(PAGES.login)
                handleCloseNavMenu()}}>
                  <Typography textAlign="center">Login</Typography>
                </MenuItem>
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={styles.adbIconXs} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={styles.logoTextXs}
          >
            QLearn
          </Typography>
          <Box sx={styles.navDesktop}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                color="dark"
                sx={styles.menuMd}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={styles.signinBox}>
            <Button
              variant="contained"
              size="large"
              sx={{ textTransform: "none" }}
              onClick={() => navigate(PAGES.login)}
            >
              Sign In
            </Button>
            {/* <Button
              variant="contained"
              sx={styles.signinStartBtn}
              size="large"
              onClick={() => navigate(PAGES.signup)}
            >
              Get Started
            </Button> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const styles = {
  adbIconMd: { display: { xs: "none", md: "flex" }, mr: 1 },
  adbIconXs: { display: { xs: "flex", md: "none" }, mr: 1 },
  logoTextMd: {
    mr: 2,
    display: { xs: "none", md: "flex" },
    fontFamily: "monospace",
    fontWeight: 700,
    letterSpacing: ".3rem",
    color: "inherit",
    textDecoration: "none",
  },
  logoTextXs: {
    mr: 2,
    display: { xs: "flex", md: "none" },
    flexGrow: 1,
    fontFamily: "monospace",
    fontWeight: 700,
    letterSpacing: ".3rem",
    color: "inherit",
    textDecoration: "none",
  },
  navMobile: { flexGrow: 1, display: { xs: "flex", md: "none" } },
  navDesktop: {
    flexGrow: 1,
    justifyContent: "flex-end",
    display: { xs: "none", md: "flex" },
  },
  menuXs: {
    display: { xs: "block", md: "none" },
  },
  menuMd: {
    my: 2,
    mx: 2,
    fontSize: 16,
    display: "block",
    textTransform: "none",
  },
  signinBox: {
    flexGrow: 0,
    display: { xs: "none", md: "flex" },
    marginLeft: 10,
  },
  signinStartBtn: { marginLeft: 5, textTransform: "none" },
};

export default ResponsiveAppBar;
