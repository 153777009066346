import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import HeaderText from "../../components/header/HeaderText";
import axios from "axios";
import { Add, ArrowBack, Delete, Edit } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { PAGES } from "../../utils/constant";
import { toast } from "react-toastify";
import { StoreContext } from "../../context/store";
import { SET_COURSES } from "../../context/actions";
import { Container } from "@mui/system";
import CourseDefault from "../../assets/img/property1.jpeg";
import { categories } from "../../utils/storageHelpers";

const ViewCourse = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [fetchedCourse, setFetchedCourse] = useState(null);
  const { state, dispatch } = useContext(StoreContext);
  const getCategoryName = (id) => {
    let category = categories.find((x) => x.id == id);
    return category ? category.name : "";
  };
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    console.log("state.courses", state.courses);
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/courses/${id}`)
      .then((res) => {
        console.log("res all", res.data);
        ///////
        let course = res.data;
        let savedCourse = course;
        // }
        ///////
        setFetchedCourse(savedCourse);
        // dispatch({ type: SET_COURSES, payload: courses })
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
        toast.error(error?.data?.errorMessage || error?.message || error?.data?.message);
      });
  }, []);

  const deleteCourse = () => {
    setIsDeleting(true);
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/courses/${id}`)
      .then((res) => {
        setShowModal(false);
        toast.success("Course deleted successfully");
        setIsDeleting(false);
        navigate(-1)
      })
      .catch((error) => {
        console.log("error", error);
        setIsDeleting(false);
        toast.error(error?.data?.errorMessage || error.message);
  
      });
  };
  return (
    <Fragment>
      {/* //Modal */}
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          {isDeleting ? (
            <Typography variant="h5">Deleting...</Typography>
          ) : (
            <>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure you want to delete course - {fetchedCourse?.title}?
              </Typography>
              <Button
                variant="contained"
                sx={{ ...styles.btn, backgroundColor: "#d32f2f" }}
                startIcon={<Delete />}
                onClick={deleteCourse}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                sx={styles.btn}
                startIcon={<ArrowBack />}
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>
      </Modal>
      {/* Property Section start */}
      <Grid container>
        <HeaderText text="View Course" paddingLeft={1} />
        {fetchedCourse && (
          <Button
            variant="contained"
            sx={styles.btn}
            startIcon={<Edit />}
            onClick={() => navigate(`${PAGES.editCourse}/${id}`)}
          >
            Edit
          </Button>
        )}
        {fetchedCourse && (
          <Button
            variant="contained"
            sx={{ ...styles.btn, backgroundColor: "#d32f2f" }}
            startIcon={<Delete />}
            onClick={() => setShowModal(true)}
          >
            Delete
          </Button>
        )}
        <Button
          variant="outlined"
          sx={styles.btn}
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Grid>
      {isLoading && (
        <Typography sx={styles.loading} variant="h3">
          🏋️‍♂️ Loading Course...
        </Typography>
      )}
      {!isLoading && fetchedCourse == null && (
        <Typography variant="h3" sx={styles.loading}>
          No Course Found 📚
        </Typography>
      )}
      {!isLoading && fetchedCourse?.title && (
        <>
          <Paper elevation={1} sx={styles.box}>
            <Box sx={styles.row}>
              <Typography variant="h6" sx={styles.headerText}>
                Title
              </Typography>
              <Typography variant="h5">{fetchedCourse.title}</Typography>
            </Box>
            <Box sx={styles.row}>
              <Typography variant="h6" sx={styles.headerText}>
                Description
              </Typography>
              <Typography variant="h5">{fetchedCourse.description}</Typography>
            </Box>
            <Box sx={styles.row}>
              <Typography variant="h6" sx={styles.headerText}>
                Category
              </Typography>
              <Typography variant="h5">
                {getCategoryName(fetchedCourse.categoryId)}
              </Typography>
            </Box>
            <Box sx={styles.row}>
              <Typography variant="h6" sx={styles.headerText}>
                Premium Course
              </Typography>
              <Typography variant="h5">
                {fetchedCourse.isPremium ? "Yes" : "No"}
              </Typography>
            </Box>
            <Box sx={styles.row}>
              <Typography variant="h6" sx={styles.headerText}>
                Image
              </Typography>
              <img
                src={
                  fetchedCourse.imageUrl && fetchedCourse.imageUrl.length > 0
                    ? fetchedCourse.imageUrl
                    : CourseDefault
                }
                height={200}
              />
            </Box>
            <Box sx={styles.row}>
              <Typography variant="h6" sx={styles.headerText}>
                Location
              </Typography>
              <Typography variant="h5">
                {fetchedCourse.location == "online" ? "Online" : "Offline"}
              </Typography>
            </Box>
            <Box sx={styles.row}>
              <Typography variant="h6" sx={styles.headerText}>
                {fetchedCourse.location == "online" ? "Course Url" : "Address"}
              </Typography>
              <Typography variant="h5">{fetchedCourse.courseUrl}</Typography>
            </Box>
          </Paper>
        </>
      )}
    </Fragment>
  );
};

const styles = {
  btn: {
    textTransform: "none",
    marginRight: "10px",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ececec",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  row: {
    borderBottomColor: "#000000",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    marginBottom: 1,
  },
  loading: { margin: "auto", marginTop: "20%" },
  box: { width: { xs: "100%", md: "95%" }, padding: 3, marginTop: 2 },
  eventsCard: { height: 550, width: "100%" },
  grid: { mt: 3 },
  headerText: { fontWeight: "bold", color: "#5B8181" },
};

export default ViewCourse;
