import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AppInput from "../inputs/AppInput";
import AppButton from "../buttons/AppButton";
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../utils/constant";
import { StoreContext } from "../../context/store";
import { getLocalStorageValue } from "../../utils/storageHelpers";
import { SET_USER } from "../../context/actions";

const AddCourseForm = ({course}) => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const initialForm = {
    title: { value: course?.title || "", error: null },
    description: { value: course?.description ||"", error: null },
    category: { value: course?.categoryId ||0, error: null },
    imageUrl: { value: course?.imageUrl ||"", error: null },
    location: { value: course?.location ||null, error: null },
    courseUrl: { value: course?.courseUrl ||"", error: null },
    isPremium: { value: course?.isPremium ||false, error: null }
  }
  const [formValues, setFormValues] = useState(initialForm);

  const onChangeInput = (event,type, property="value") =>{
    let value = event.target[property];
    let copyFormValues = {...formValues};
    copyFormValues[type] = {...copyFormValues[type], value}
    setFormValues({...formValues, ...copyFormValues})
  }

  const handleSubmit = (submit) => {
    console.log('formValues', formValues)
    let copyFormValues = {...formValues};
    let hasError = false;
    if(copyFormValues.title.value.length <2){
      hasError = true;
      copyFormValues.title.error = "Minimum of 2 characters required";
    }else{
      copyFormValues.title.error = null
    }
    if(copyFormValues.description.value.length < 5){
      hasError = true;
      copyFormValues.description.error = "Minimum of 5 characters required";
    }else{
      copyFormValues.description.error = null
    }
    if(copyFormValues.category.value == 0){
      hasError = true;
      copyFormValues.category.error = "Please select a category";
    }else{
      copyFormValues.category.error =  null
    }
    if(copyFormValues.imageUrl.value.length > 0 && !copyFormValues.imageUrl.value.toLowerCase().includes("http://") && !copyFormValues.imageUrl.value.toLowerCase().includes("https://") ){
      hasError = true;
      copyFormValues.imageUrl.error = "Please enter a valid url"
    }else{
      copyFormValues.imageUrl.error =  null
    }
    if(!copyFormValues.location.value){
      hasError = true;
      copyFormValues.location.error = "Please pick a course location"
    }else{
      copyFormValues.location.error =  null
    }
    if(copyFormValues.location.value){
      if(copyFormValues.location.value == "online" && (!copyFormValues.courseUrl.value.toLowerCase().includes("http://") && !copyFormValues.courseUrl.value.toLowerCase().includes("https://")) && !copyFormValues.courseUrl.value.toLowerCase().includes("youtube.com") ){
        hasError = true;
        copyFormValues.courseUrl.error = "Please enter a valid Youtube video url"
      }else if(copyFormValues.location.value == "offline" && copyFormValues.courseUrl.value.length < 10){
        hasError = true;
        copyFormValues.courseUrl.error = "Please enter a valid address"
      }else{
        copyFormValues.courseUrl.error =  null
      }
    }else{
      copyFormValues.courseUrl.error =  null
    }

    if(hasError){
      console.log('final fail', formValues)
      setFormValues(copyFormValues)
      if(submit == true){
        toast.error("Please fill form properly")
      }
      return
    }
    console.log('submit', submit)
    if(!submit){
      return;
    }
    if(copyFormValues.isPremium.value ==true && !state.user.isPremium){
      toast.error("Only premium user can create premium courses")
      return;
    }
    setIsLoading(true)
    const payload={
      title : copyFormValues.title.value,
      categoryId: copyFormValues.category.value,
      description: copyFormValues.description.value,
      courseUrl: copyFormValues.courseUrl.value,
      location: copyFormValues.location.value,
      isPremium: copyFormValues.isPremium.value,
      imageUrl: copyFormValues.imageUrl.value.length > 0 ? copyFormValues.imageUrl.value : null
    }
    setFormValues(copyFormValues)
    if(course){
      axios.put(`${process.env.REACT_APP_BASE_URL}/courses/${course.id}`, payload)
      .then((res)=>{
        console.log('res', res)
        toast.success("Course updated successfully")
        setIsLoading(false)
        setTimeout(() => {
         navigate(-1)
        }, 1000);
      }).catch(error=>{
        setIsLoading(false)
        toast.error(error?.data?.errorMessage || error?.message || error?.data?.message)
      })
    }else{
      axios.post(`${process.env.REACT_APP_BASE_URL}/courses`, payload)
      .then((res)=>{
        console.log('res', res)
        toast.success("Course created successfully")
        setIsLoading(false)
        setTimeout(() => {
         navigate(PAGES.courses)
        }, 1000);
      }).catch(error=>{
        setIsLoading(false)
        toast.error(error?.data?.errorMessage || error?.message || error?.data?.message)
      })
    }
    
  };

  return (
    <Container>
      <FormControl fullWidth>
        <AppInput label="Title*" error={formValues.title.error} onChange={(e)=> onChangeInput(e,"title")} value={formValues.title.value} onBlur={()=>handleSubmit(null)}  />
        <AppInput label="Description*" multiline error={formValues.description.error} onChange={(e)=> onChangeInput(e,"description")} value={formValues.description.value} onBlur={()=>handleSubmit(null)} />
        <Box sx={styles.selectBox}>
          <Typography sx={{ marginBottom: 1 }}>Category*</Typography>
          <Select
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formValues.category.value}
            onChange={(e)=>onChangeInput(e,"category")}
            inputProps={{"data-testid": "category"}}
          >
            <MenuItem prop value={0}>--Select Category--</MenuItem>
            <MenuItem value={1}>Quality Assurance</MenuItem>
            <MenuItem value={2}>Product Design</MenuItem>
            <MenuItem value={3}>Software Development</MenuItem>
          </Select>
          {formValues.category.error && <Typography style={styles.error}>{formValues.category.error}</Typography>}
        </Box>

        <FormControlLabel
          control={<Checkbox checked={formValues.isPremium.value} onChange={(e)=>onChangeInput(e,"isPremium","checked")}  inputProps={{"data-testid": "isPremium"}} />}
          label="Premium Course"
        />
        <AppInput label="Image URL" error={formValues.imageUrl.error} onChange={(e)=> onChangeInput(e,"imageUrl")} value={formValues.imageUrl.value} onBlur={handleSubmit}   />

        <FormLabel id="demo-row-radio-buttons-group-label">Location*</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            control={
              <Radio
                value="online"
                checked={formValues.location.value == "online"}
                onChange={(e)=>onChangeInput(e,"location")}  inputProps={{"data-testid": "online"}}
              />
            }
            label="Online"
          />
          <FormControlLabel
            control={
              <Radio
                value="offline"
                checked={formValues.location.value == "offline"}
                onChange={(e)=>onChangeInput(e,"location")}  inputProps={{"data-testid": "offline"}}
              />
            }
            label="Offline"
          />
        </RadioGroup>
        {formValues.location.error && <Typography style={styles.error}>{formValues.location.error}</Typography>}

        {formValues.location.value && (
          <AppInput
            label={
              formValues.location.value == "online" ? "Course URL* (must be youtube)" : "Address*"
            } error={formValues.courseUrl.error} onChange={(e)=> onChangeInput(e,"courseUrl")} value={formValues.courseUrl.value}  onBlur={()=>handleSubmit(null)}
          />
        )}

        <AppButton
          btnText={course ? "Update Course":"+ Add Course"}
          customStyles={{ marginTop: 6 }}
          onClick={() =>handleSubmit(true)}
          isLoading={isLoading}
        />
      </FormControl>
    </Container>
  );
};

const styles = {
  selectBox: {
    marginBottom: 3,
  },
  error:{
    color: "red",
    fontSize: 12
  }
};

export default AddCourseForm;
