import { createTheme } from '@mui/material/styles';
import { APP_COLOURS } from '../utils/constant';

export const AppTheme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: APP_COLOURS.primary,
    //   dark: APP_COLOURS.primaryDarker,
    },
    white: {
      main: APP_COLOURS.white
    },
    dark: {
      main: APP_COLOURS.dark
    },
    grey: {
      main: APP_COLOURS.grey
    },
    sidebarText: {
      main: "#9E9E9E"
    }
  },
  components: {
    // Overide BUTTON styles
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          height: '48px',
        },
      },
    },
  },
});