import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import axios from "axios";
import HeaderText from "../../components/header/HeaderText";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import AddCourseForm from "../../components/courses/AddCourseForm";
import { toast } from "react-toastify";

const EditCourse = () => {
    const { id } = useParams();
  const navigate = useNavigate();
  const [fetchedCourse, setFetchedCourse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/courses/${id}`)
      .then((res) => {
        console.log("res all", res.data);
        ///////
        let course = res.data;
        let savedCourse = course;
        // }
        ///////
        setFetchedCourse(savedCourse);
        // dispatch({ type: SET_COURSES, payload: courses })
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
        toast.error(error?.data?.errorMessage || error.message);
      });
  }, []);

  return (
    <Fragment>
      {/* Property Section start */}
      <Grid container>
        <HeaderText text="Edit Course" paddingLeft={1} />
        <Button
          variant="outlined"
          sx={styles.btn}
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Grid>
      {isLoading ?  <Typography sx={styles.loading} variant="h3">
          🏋️‍♂️ Loading Course...
        </Typography> :<AddCourseForm course={fetchedCourse}  />}
    </Fragment>
  );
};

const styles = {
  btn: {
    textTransform: "none",
    marginRight: "10px",
  },
  loading: { margin: "auto", marginTop: "20%" },
};

export default EditCourse;
