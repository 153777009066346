import React from "react";
import FeaturedProperties from "../../components/landing-page/FeaturedProperties";
import Hero from "../../components/landing-page/HeroSection";
import Services from "../../components/landing-page/Services";
import WhyChooseUs from "../../components/landing-page/WhyChooseUs";
import Feedback from "../../components/landing-page/Feedback";
import Footer from "../../components/footer/Footer";
import ResponsiveAppBar from "../../components/navbar/ResponsiveAppBar";

export const Home = () => {
  return (
    <>
      <ResponsiveAppBar />
      <Hero />
      <Footer />
    </>
  );
};
