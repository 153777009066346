import * as React from "react"

const GoogleIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.744 5.04c1.584-.527 3.36-.527 4.944.049.864.336 1.68.816 2.352 1.488l-.72.72-1.344 1.344a3.524 3.524 0 0 0-1.584-.912 4.676 4.676 0 0 0-2.16-.096c-.816.192-1.584.624-2.208 1.2a4.32 4.32 0 0 0-1.056 1.728c-.816-.624-1.584-1.248-2.4-1.872A7.607 7.607 0 0 1 9.744 5.04Z"
      fill="#EA4335"
    />
    <path
      d="M4.944 10.562a9.523 9.523 0 0 1 .624-1.873c.816.624 1.584 1.248 2.4 1.873-.336.912-.336 1.92 0 2.88-.816.623-1.584 1.248-2.4 1.871-.72-1.488-.96-3.168-.624-4.752Z"
      fill="#FBBC05"
    />
    <path
      d="M12.144 10.61h6.912c.24 1.295.192 2.64-.192 3.887a6.842 6.842 0 0 1-1.872 3.072c-.768-.624-1.536-1.2-2.352-1.824a3.647 3.647 0 0 0 1.488-2.256h-3.984v-2.88Z"
      fill="#4285F4"
    />
    <path
      d="M5.568 15.313c.816-.624 1.584-1.248 2.4-1.872a4.408 4.408 0 0 0 1.68 2.256c.48.336 1.056.576 1.632.72a5.51 5.51 0 0 0 1.776 0 4.803 4.803 0 0 0 1.632-.624c.768.624 1.536 1.2 2.352 1.824a7.056 7.056 0 0 1-3.024 1.584c-1.248.288-2.544.288-3.744-.048-.96-.24-1.872-.72-2.688-1.344-.816-.72-1.536-1.584-2.016-2.496Z"
      fill="#34A853"
    />
  </svg>
)

export default GoogleIcon
