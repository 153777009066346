import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { blueGrey, grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import ApartmentIcon from "@mui/icons-material/Apartment";

const ServicesList = [
  {
    id: 1,
    header: "Buy Properties",
    body: "Janniel Housing Estate is an integrated, mixed-use city planned for over 2,000 hectares in the Lekki Free Zone. Over 50 companies are m...",
    icon_bgcolor: blueGrey[200],
    icon_color: blueGrey[50],
    bgcolor: blueGrey[500],
    textColor: blueGrey[50],
  },
  {
    id: 2,
    header: "Rent Properties",
    body: "Janniel Housing Estate is an integrated, mixed-use city planned for over 2,000 hectares in the Lekki Free Zone. Over 50 companies are m...",
    icon_bgcolor: blueGrey[200],
    icon_color: blueGrey[500],
    bgcolor: blueGrey[50],
    textColor: grey[900],
  },
  {
    id: 3,
    header: "Sell Properties",
    body: "Janniel Housing Estate is an integrated, mixed-use city planned for over 2,000 hectares in the Lekki Free Zone. Over 50 companies are m...",
    icon_bgcolor: blueGrey[200],
    icon_color: blueGrey[500],
    bgcolor: blueGrey[50],
    textColor: grey[900],
  },
];

const Services = () => {
  return (
    <Box sx={styles.box}>
      <Typography variant="h5" sx={{ mb: "30px" }}>
        The Services you will get from us:
      </Typography>
      <Box component="div" sx={styles.cardDiv}>
        {ServicesList.map((serviceList) => {
          return (
            <Card sx={styles.card(serviceList.bgcolor)} key={serviceList.id}>
              <CardHeader
                avatar={
                  <Avatar
                    sx={{
                      bgcolor: `${serviceList.icon_bgcolor}`,
                      color: `${serviceList.icon_color}`,
                    }}
                  >
                    <ApartmentIcon />
                  </Avatar>
                }
              />
              <CardContent sx={styles.cardHeader(serviceList.textColor)}>
                <Typography variant="h6" gutterBottom component="div">
                  {serviceList.header}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {serviceList.body}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

const styles = {
  box: {
    pt: 5,
    pb: 7,
    width: "90%",
    mx: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardDiv: {
    width: "100%",
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    gap: 3,
  },
  card: (bgcolor) => {
    return {
      width: { xs: "90%", md: "30%" },
      backgroundColor: bgcolor,
      mx: {
        xs: "auto",
      },
    };
  },
  cardHeader: (color) => {
    return {
      color: color,
    };
  },
};

export default Services;
