export const APP_COLOURS = {
    primary: "#5B8181",
    primaryDarker: "#2d4040",
    white: "#ffffff",
    dark: "#1f1f1f",
    lightDark: "#383838",
    grey: "#B3BAC5"
}

export const PAGES = {
    login :"/login",
    signup: "/signup",
    dashboard: "/dashboard",
    courses: "/courses",
    addCourse: "/courses/add",
    editCourse: "/courses/edit"
}