import React, { Fragment } from "react";
import { Box, Button, Card, Grid } from "@mui/material";
import HeaderText from "../../components/header/HeaderText";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AddCourseForm from "../../components/courses/AddCourseForm";

const AddCourse = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      {/* Property Section start */}
      <Grid container>
        <HeaderText text="Add New Course" paddingLeft={1} />
        <Button
          variant="outlined"
          sx={styles.btn}
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Grid>
      <AddCourseForm />
    </Fragment>
  );
};

const styles = {
  btn: {
    textTransform: "none",
    marginRight: "10px",
  },
};

export default AddCourse;
