import { useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Layout from "../containers/layout/Layout";
import { SET_USER } from "../context/actions";
import { StoreContext } from "../context/store";
import { getLocalStorageValue } from "../utils/storageHelpers";
import { toast } from "react-toastify";

const PrivateRoute = ({ children, noLayout }) => {
  const { state, dispatch } = useContext(StoreContext); // useAuth();
  let location = useLocation();

  useEffect(()=>{
    console.log("I ran")
  if (!state.user) {
      let user = getLocalStorageValue("user")
      if(user){
        let storeUser = JSON.parse(user)
        dispatch({ type: SET_USER, payload: storeUser })
      }else{
        toast.error("401: Unauthorized User ")
        return <Navigate to="/login" state={{ from: location }} />;
      }
    }
  },[dispatch, location.pathname])
  

  return noLayout ? children : <Layout>{children}</Layout>;
};

export default PrivateRoute;
