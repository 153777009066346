import { Box, Button, Container, Divider, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { toast } from 'react-toastify';
import AppInput from "../inputs/AppInput";
import AppButton from "../buttons/AppButton";
import { APP_COLOURS, PAGES } from "../../utils/constant";
import GoogleIcon from "../../assets/svg/GoogleIcon";
import { useNavigate } from "react-router-dom";
import { containsLetterAndDigit, validateEmail } from "../../utils/stringHelpers";
import { StoreContext } from "../../context/store";
import { SET_USER } from "../../context/actions";

const LoginForm = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(StoreContext); 
  const [loginForm, setLoginForm] = useState({
    email: { value: "", error: null },
    password: { value: "", error: null },
  });
  const [formError, setFormError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const users = [
    {email: "ay@mail.com", password: "pass1234", name: "Ayobami", isPremium: false},
    {email: "ayp@mail.com", password: "pass1234", name: "Ayobami Premium", isPremium: true},
    {email: "akin@mail.com", password: "pass1234", name: "Akin", isPremium: false},
    {email: "akinp@mail.com", password: "pass1234", name: "Akin Premium", isPremium: true},
    {email: "test@mail.com", password: "pass1234", name: "Test User",isPremium: false},
    {email: "testp@mail.com", password: "pass1234", name: "Test Premium",isPremium: true},
    // {email: "", password: ""},
    // {email: "", password: ""}
  ]

  const onChangeInput = (event,type) =>{
    let value = event.target.value;
    let copyLogin = {...loginForm};
    copyLogin[type] = {...copyLogin[type], value}
    setLoginForm({...loginForm, ...copyLogin})
  }
  const setFormErrorMessage =(errors)=>{
    let copyLogin = {...loginForm};
    copyLogin.password = {...copyLogin.password, error: errors.password }
    copyLogin.email = {...copyLogin.email, error: errors.email }
    setLoginForm({...loginForm, ...copyLogin})
  }

  const submitForm = ()=>{
    let errors = {email:null, password: null};
    if(!validateEmail(loginForm.email.value.length == 0 || loginForm.email.value)){
      errors.email =  "Email is invalid"
    }else{
      errors.email = null;
    }
    if(loginForm.password.value.length < 8 || !containsLetterAndDigit(loginForm.password.value) ){
      errors.password =  "Password must be 8 characters with at least one letter and one digit"
    }else{
      errors.password = null;
    }
    setFormErrorMessage(errors)
    if(errors.email || errors.password) return;
    setIsLoading(true)
    let loggedInUser = users.find(x => x.email == loginForm.email.value.toLowerCase() && x.password == loginForm.password.value)
    if(!loggedInUser){
      toast.error("Invalid Login Credential");
      setFormError("Invalid Login Credential")
      setTimeout(() => {
        setIsLoading(false)
      }, 1000);
      return;
    }else{
      setFormError(null)
    }

    dispatch({ type: SET_USER, payload: {token: "sdfgrewee",...loggedInUser} })
    toast.success("Login Successfull");
    setIsLoading(false)
    navigate(PAGES.courses)
  }

  return (
    <Container>
      <Typography sx={styles.headerText}>Login</Typography>
      {formError && <Typography sx={styles.formError}>{formError}</Typography>}
      <AppInput
        label="Email Address"
        value={loginForm.email.value}
        onChange={(e) => onChangeInput(e, "email")}
        error={loginForm.email.error}
      />
      <AppInput
        label="Password"
        value={loginForm.password.value}
        onChange={(e) => onChangeInput(e, "password")}
        noBottomPad
        type="password"
        error={loginForm.password.error}
      />
      <Button color="primary" sx={styles.fgPassword}>
        Forgot Password?
      </Button>
      <AppButton
        btnText="Login"
        isLoading={isLoading}
        customStyles={{ marginTop: 6 }}
        onClick={submitForm}
      />
      <Divider sx={styles.divider}>OR</Divider>
      <Box sx={styles.continueBox}>
        <Button
          sx={styles.continueBtn}
          variant="text"
          startIcon={<GoogleIcon />}
        >
          Continue with Google
        </Button>
      </Box>
      {/* <Box sx={styles.dontHaveAcc}>
        <Typography sx={styles.dontHaveText}>Dont have and account?</Typography>
        <Button
          color="primary"
          variant="text"
          onClick={() => navigate(PAGES.signup)}
          sx={styles.createAcc}
        >
          Create Account
        </Button>
      </Box> */}
    </Container>
  );
};

const styles = {
  headerText: {
    fontSize: 38,
    fontWeight: 700,
    textAlign: "center",
    marginBottom: 4,
    color: APP_COLOURS.dark,
  },
  fgPassword: {
    textTransform: "none",
    fontSize: 16,
    float: "right",
    padding: 0,
    marginTop: "12px",
  },
  divider: { my: { xs: 3, md: 4 } },
  continueBox: { display: "flex", justifyContent: "center" },
  continueBtn: { textTransform: "none", fontSize: 16, color: APP_COLOURS.dark },
  dontHaveAcc: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    my: 3,
    dontHaveText: { fontSize: 16, color: APP_COLOURS.dark, marginTop: "2px" },
    createAcc: {
      fontSize: 16,
      marginLeft: "5px",
      textTransform: "none",
      padding: 0,
      textDecoration: "underline",
    },
  },
  formError:{
    color:"red",
textAlign:"center"
  }
};

export default LoginForm;
