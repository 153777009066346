import React from "react";
import PropTypes from "prop-types";
import { Box, FormLabel, TextField, Typography } from "@mui/material";
import { APP_COLOURS } from "../../utils/constant";

const AppInput = ({
  variant,
  value,
  onChange,
  label,
  placeholder,
  fullWidth,
  noBottomPad,
  bgColor,
  id,
  type="text",
  error,
  multiline,
  rows =3,
  onBlur,
  testId
}) => {
  return (
    <Box sx={styles.box(noBottomPad)}>
       <FormLabel sx={styles.inputText} id={label.replace(/\s/g,'')}>{label}</FormLabel>
      <TextField
        type={type}
        variant={variant}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        fullWidth={fullWidth}
        sx={styles.inputBg(bgColor)}
        id={id}
        multiline={multiline}
        minRows={rows}
        maxRows={rows}
        onBlur={onBlur}
        inputProps={{ "data-testid": label.replace(/\s/g,'')}}
      />
      {error && <Typography style={styles.error}>{error}</Typography>}
    </Box>
  );
};

const styles = {
  box: (noBottomPad) => {
    return { marginBottom: noBottomPad ? "0px" : "24px" };
  },
  inputText: { color: APP_COLOURS.lightDark, marginBottom: 1 },
  inputBg: (bgColor) => {
    return { bgcolor: bgColor };
  },
  error:{
    color: "red",
    fontSize: 12
  }
};

AppInput.propTypes = {
  variant: PropTypes.string,
  onChange: PropTypes.func,
};

AppInput.defaultProps = {
  variant: "outlined",
  label: "",
  fullWidth: true,
  onChange: () => {},
};

export default AppInput;
